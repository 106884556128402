<template>
    <div :class="['flex flex-col w-full', disabled ? 'opacity-50' : 'opacity-100']">
        <label v-if="label" :class="['block text-sm font-medium mb-1', hasError ? 'text-red-700' : 'text-gray-700']">{{ label }} {{ required ? "*" : '' }}</label>
        <div class="flex flex-col w-full ">
            <div :class="['flex items-center w-full border shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md px-3', hasError ? 'border-red-700' : 'border-gray-300']">
                <input :type="inputType"
                       class="border-0 w-full px-0 focus:outline-none focus:ring-0 placeholder-gray-300"
                       :placeholder="placeholder"
                       :disabled="disabled"
                       @blur="onBlur"

                       v-model="inputValue"/>
                <i v-if="type === 'password'" :class="['fad fa-eye cursor-pointer ml-3', type === 'password' ? 'opacity-50' : 'opacity-100']"
                   tabindex="0"
                   style="font-size: 18px;"
                   @click="togglePassword"
                />
            </div>
            <span v-if="hasError" class="mt-1 text-red-700 text-xs"><i class="fal fa-exclamation-triangle text-sm mr-2"/>{{ error }}</span>
            <span v-if="hasSuccess" class="mt-1 text-green-700 text-xs"><i class="far fa-check text-sm mr-2"/> {{ success }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "input-group",
    props: {
        label: {type: String},
        type: {type: String, default: 'text'},
        disabled: {type: Boolean, default: false},
        error: {type: [String], default: '', required: false},
        success: {type: [String], default: '', required: false},
        placeholder: {type: String, default: ''},
        value: {type: [String, Number], default: ''},
        required: {type: Boolean, default: false},
        onlyNumbers: {type: Boolean, default: false}
    },
    data() {
        return {
            inputValue: this.$props.value,
            inputType: this.$props.type
        }
    },

    watch: {
        inputValue() {
            let value = this.inputValue;
            if (this.inputType === 'number') {
                value = Math.abs(value)
            }
            this.$emit('on-change', value)
        },
        value(newVal) {
            this.inputValue = newVal
        }
    },

    computed: {
        hasError() {
            return this.$props.error !== '' && this.$props.error !== null && this.$props.error !== undefined;
        },
        hasSuccess() {
            return this.$props.success !== '' && this.$props.success !== null && this.$props.success !== undefined;
        }
    },

    methods: {
        onBlur(event) {
            if (!event.relatedTarget || event.relatedTarget.nodeName !== 'I') {
                this.$emit('on-blur', this.inputValue)
            }
        },

        togglePassword() {
            this.inputType = this.inputType === 'password' ? 'text' : 'password';
        }
    }

}
</script>

<style scoped>

</style>